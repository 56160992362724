.extrude__navbar{
    padding: 1rem 4rem;
    display: flex;
    flex-direction: row;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
}


.extrude__navbar-menu{
    margin-left: 2rem;
    display: none; 
    position: relative;    
}


.extrude__navbar-menu__container-links{
    display:flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: end;
    background-color: var(--color-2);
    position: absolute;
    top: 30px;
    right: 0;
    margin-top: 2rem;
    min-width: 210px;
    padding: 1rem;
}

.extrude__navbar-menu__container-links a{

    font-family: var(--font-family);
    color: var(--color-5);
    padding: 0.5rem;
    font-size: 16px;
}


.extrude__navbar-logo img{
    height: 80px;
}


.extrude__navbar-links{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding: 0rem 0rem;

}

.extrude__navbar-links a{
    padding: 0rem 1rem 0rem 1rem;
    font-family: var(--font-family);
    color: var(--color-3);
}

.extrude__navbar-links a:hover{
    border-bottom: 3px solid;
    border-color: var(--color-4);
    transition: 0.1s ease-out;
    font-weight: 800;

}

/*.extrude__navbar-links a.active{
    border-bottom: 3px solid;
    border-color: var(--color-4);
    font-weight: 800;
    
} */

@media screen and (max-width: 991px){
    .extrude__navbar{
        padding: 2rem;
        top: 0;
    }

    .extrude__navbar-links{
        display: none;
    }

    .extrude__navbar-menu{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        z-index: 1;
    }

    .extrude__navbar-store{
        display: none;
    }
    
    
}




.extrude__navbar-links button{
    width: 100px;
    height: 36px;
    background: var(--color-4);
    border-radius: 10px;
    font-family: var(--font-family);
    color: var(--color-6);
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;
    float: right;
}

.extrude__navbar button{
    width: 100%;
    height: 36px;
    background: var(--color-4);
    border-radius: 10px;
    font-family: var(--font-family);
    color: var(--color-6);
    font-weight: 400;
    font-size: 14px;
    border:none;
    text-transform: uppercase;
    float: right;
}


