*{
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 1.5rem;
  }

h1 { 
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 60px;
  color: var(--color-2);
}

h2 { 
  font-family: var(--font-family);
  font-weight: 200;
  font-size: 30px;
  color: var(--color-2);
}

h3 { 
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 50px;
  color: var(--color-2);
}

@media screen and (max-width:768px) {
  h3{
      font-size: 40px;
  }

 h2{
      font-size: 30px;
  }
  
}

h4 { 
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 30px;
  color: var(--color-2);
  line-height: 4rem;
}

h5 { 
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  color: var(--color-2);
  line-height: 4rem;
}


body{
    background-color: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}


.content__padding{
  padding: 6rem 6rem;
}

@media screen and (max-width:680px) {
  .content__padding{
    padding: 4rem 2rem;
  }
  
}

