@font-face {
  font-family: "Karla";
  src: url("./Fonts/Karla-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Karla";
  src: url("./Fonts/Karla-Light.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Karla";
  src: url("./Fonts/Karla-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Karla";
  src: url("./Fonts/Karla-SemiBold.ttf");
  font-weight: 600;
}

:root {
    --color-bg: #FFFFFF;
    --color-1: #4898a0;
    --color-2: #403c3d;
    --color-3: #141414;
    --color-4: #FFFFFF;
    --color-5: rgba(72, 152, 160, 0.7 );
    --color-6: rgba(255, 255, 255, 0.9 );
    --color-grad: linear-gradient(180deg, #105D6B 25.16%, #12A7B5 63.62%);
    --font-family: 'Karla'; 
}

