.extrude__NoPage{
    background-image: url(../../Assets/bg-img.jpg);
    background-size: cover;
    background-position: center;
    background-blend-mode: luminosity;
    background-color: var(--color-1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.extrude__NoPage_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    background-color: var(--color-6);
    padding: 2rem;
    border-radius: 10px;
}


.extrude__NoPage_content h3{
    color: var(--color-3);

    text-align: center;
}

.extrude__NoPage_content p{
    color: var(--color-3);
    text-align: center;
    padding: 1rem;
}

.extrude__NoPage_content a{
    color: var(--color-bg);
    font-family: var(--font-family);
    background-color: var(--color-1);
    padding: 0.5rem 1rem;
    margin: 1rem;
    border-radius: 1rem;
}

.extrude__NoPage_content a:hover{
    transition: 0.5s ease-out;
    letter-spacing: 0.5px;
    color: var(--color-bg);

}