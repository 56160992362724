.details{
    padding: 2rem;
    margin: 0.5rem;
    background-color: var(--color-4);
    border-radius: 10px;
}

.details h4{
line-height: 30px;
}

.details img{
    height: 50px;
}