.extrude__contact-content{
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1024px){
    .extrude__contact-content{
        flex-direction: column;
        padding: 2rem 2rem;
    }
}

.extrude__contact-content__social{
    padding: 0rem 4rem 2rem 4rem;
}

    @media screen and (max-width:768px){
        .extrude__contact-content__social{
            padding: 0rem 0rem 2rem 0rem;
        }
        
    }


    @media screen and (max-width:560px) {
        .extrude__contact-text {
            padding: 0rem 0rem;
    
        }
        
    }

.extrude__contact-content__form{
    padding: 0rem 2rem;
    flex: 0.75;
}

@media screen and (max-width:768px) {
    .extrude__contact-content__form{
        padding: 0;
    }
    
}


.extrude__contact-content__form-field input{
    padding: 0.5rem 2rem;
    width: 100%;
    height: 40px;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-2);
    color: var(--color-2);
    border: none;
    font-family: var(--font-family);
    font-size: 16px;
}

.extrude__contact-content__form-field textarea{
    padding: 0.5rem 2rem;
    width: 100%;
    height: 150px;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-2);
    border: none;
    color: var(--color-2);
    border: none;
    font-family: var(--font-family);
    font-size: 16px;
}

.extrude__contact-content__form-field button{
    padding: 14px 44px;
    width: 100%;
    height: 50px;
    left: 20px;
    top: 20px;
    background: var(--color-1);
    border-radius: 11px;
    color: var(--color-4);
    border:none;
    text-transform: uppercase;
}

.extrude__contact-content__form-field button:hover{
    scale: 1.05;
    transition: 1s ease-out;
}