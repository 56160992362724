.extrude__home-landing{
    background: url(../../Assets/bg-img.jpg);
    background-blend-mode: luminosity;
    background-size: cover;
    background-color: var(--color-1);
}

.extrude__home-landing__content{
    background-color: var(--color-5);
    padding: 2rem 2rem;
    width: 70%;
    color: var(--color-4);
}

@media screen and (max-width:768px) {
    .extrude__home-landing__content{
        width: 100%;
    }
    
  }

.extrude__home-landing__content h1{
    color: var(--color-4);
}

@media screen and (max-width:768px) {
    .extrude__home-landing__content h1{
        font-size: 40px;
    }
    
  }



.extrude__home-landing__button{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.extrude__home-services{
    display: flex;
    margin: -120px 0px 0px 0px;
    flex-wrap:wrap
}

@media screen and (max-width:680px) {
    .extrude__home-services{
        flex-direction: column;
    }
    
  }

.extrude__home-about{
    display: flex;
}

@media screen and (max-width:1024px) {
    .extrude__home-about{
        flex-direction: column;
    }
    
  }

.extrude__home-about__content{
    padding: 2rem;
    display: flex;
    text-align: right;
    flex-direction: column;
}

.extrude__home-about__image img{
    border-radius: 1rem;
    width: 500px;
}

@media screen and (max-width:680px) {
    .extrude__home-about__image img{
        width: 100%;
    }
    
  }

.extrude__home-projects{
    background-color: var(--color-1);
    display: flex;
    flex-wrap:wrap-reverse;
    align-items: center;
}

.extrude__home-projects__content h2 {
    color: var(--color-4);
}

.extrude__home-projects__content h3 {
    color: var(--color-4);
}

.extrude__home-projects__images{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    justify-content: center;  
}

.extrude__home-projects__images img{
  height: 200px;
  padding: 0.2rem;
  border-radius: 10px;
}

@media screen and (max-width:680px) {
    .extrude__home-projects__images img{
        width: 100%;
    }
    
  }

.extrude__home-partners{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.extrude__home-partners img{
    height: 70px;
    margin: 1rem;
}
