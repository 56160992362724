
.extrude__home-contact{
    background-color: var(--color-1);
}

.extrude__home-contact__heading h2 {
    color: var(--color-4);
}

.extrude__home-contact__heading h3 {
    color: var(--color-4);
}

@media screen and (max-width:768px) {
    .extrude__home-contact__heading h3{
        font-size: 40px;
    }

    .extrude__home-contact__heading h2{
        font-size: 30px;
    }
    
  }

.extrude__home-contact__details{
    display: flex;
    padding: 1rem;
    margin: 1rem;
    flex-wrap: wrap;
}

@media screen and (max-width:768px) {
    .extrude__home-contact__details{
        padding: 0;
        margin: 0;
    }
  }